@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  25% {
    background-position: 50% 100%;
  }
  50% {
    background-position: 100% 50%;
  }
  75% {
    background-position: 50% 0%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animated-gradient {
  background: linear-gradient(45deg, 
    rgba(60, 173, 224, 1) 0%,
    rgba(62, 204, 124, 1) 25%,
    rgba(30, 58, 95, 1) 50%,
    rgba(62, 204, 124, 1) 75%,
    rgba(60, 173, 224, 1) 100%
  );
  background-size: 400% 400%;
  animation: gradient 30s ease infinite;
}