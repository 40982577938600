@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
  width: 100%;
}

/* Globalne style dla customowego scrollbara */
::-webkit-scrollbar {
    width: 8px; /* Ustawiamy szerokość scrollbara */
    background-color: #e0e0e0; /* Szare tło scrollbara */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #1b1b1b; /* Czarny kolor "thumb" */
    border-radius: 4px; /* Zaokrąglenie scrollbara */
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #333333; /* Jaśniejszy czarny podczas najechania */
  }
  
  ::-webkit-scrollbar-track {
    background-color: #e0e0e0; /* Szare tło "track" */
  }
/* Customowy scrollbar tylko dla klasy .custom-scroll */
.custom-scroll::-webkit-scrollbar {
    width: 8px;
    background-color: #e0e0e0;
  }
  @media (max-width: 640px) {
    .custom-scroll::-webkit-scrollbar {
        width: 4px;
    }
  }
  
  .custom-scroll::-webkit-scrollbar-thumb {
    background-color: #1b1b1b;
    border-radius: 4px;
  }
  
  .custom-scroll::-webkit-scrollbar-thumb:hover {
    background-color: #333333;
  }
  
  .custom-scroll::-webkit-scrollbar-track {
    background-color: #e0e0e0;
  }
    